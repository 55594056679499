import { useSelector } from "react-redux";
import { OpenStackResourceOrOther } from "../../openstack/types";
import { getCurrentProjectName, getProjects } from "../../selectors/projects";
import { keysOf } from "../../shared-functions/objects";
import { MergedProjectsByNameAndId } from "../../shared-functions/projects";
import { State } from "../../selectors/state";

const useIsSharedNetwork = (network?: OpenStackResourceOrOther) => {
  const projectsList = useSelector<State>(
    getProjects,
  ) as MergedProjectsByNameAndId;

  const currentProjectName = useSelector<State>(
    getCurrentProjectName,
  ) as string;

  const currentProject = projectsList[currentProjectName];

  return !keysOf(currentProject).includes(network?.project_id as string);
};

export default useIsSharedNetwork;
