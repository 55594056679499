import RulesTable from "./bits/RulesTable";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { toggleSlidingMenu } from "../../../../actions/toggleSlidingMenu";
import { checkUserCRUDAccess } from "../../../../shared-functions/authenticate";
import { Tab } from "semantic-ui-react";

const Rules = ({ securitygroup, header }) => {
  const dispatch = useDispatch();

  const privileges = useSelector((state) => state.login.userlogin.privileges);
  const hasCRUDAccess = checkUserCRUDAccess(privileges);

  return (
    <Tab.Pane className="padding-top-30">
      <RulesTable
        securitygroup={securitygroup}
        hasCRUDAccess={hasCRUDAccess}
        header={header}
      />

      {hasCRUDAccess ? (
        <div className="flexbox row-reverse ">
          <button
            className="float-right button button--green"
            onClick={() =>
              dispatch(
                toggleSlidingMenu(
                  "create",
                  "Security Group Rule",
                  securitygroup,
                ),
              )
            }
          >
            <span>Create new rule</span>
          </button>
        </div>
      ) : null}
    </Tab.Pane>
  );
};

Rules.propTypes = {
  securitygroup: PropTypes.object.isRequired,
  header: PropTypes.string,
};

export default Rules;
