import { UnknownResource, ValidOpenstackResource } from "../openstack/types";
import { MergedProjectsById } from "../shared-functions/projects";
import { State } from "./state";

export type SelectorReturnType = {
  currentPage: number;
  zonesLeft: number;
  hiddenRegions: string[];
  selectedResources: string[];
  resourcesList: { [key: string]: ValidOpenstackResource | UnknownResource };
  sortColumn: string;
  sortOrder: string;
  detailedViewList: string[];
};

export const getCleuraUsersFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.cleura_users.CURRENT_PAGE,
    zonesLeft: state.cleura_users.CLEURA_USERS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.cleura_users.HIDDEN_REGIONS,
    selectedResources: state.cleura_users.CLEURA_USERS_LIST_SELECTED,
    resourcesList: state.cleura_users.CLEURA_USERS_LIST,
    sortColumn: state.cleura_users.CLEURA_USERS_LIST_SORT_COLUMN,
    sortOrder: state.cleura_users.CLEURA_USERS_LIST_SORT_ORDER,
    detailedViewList: state.cleura_users.CLEURA_USERS_VIEWMORE,
  };
};

export const getClustersFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.clusters.CURRENT_PAGE,
    zonesLeft: state.clusters.CLUSTERS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.clusters.HIDDEN_REGIONS,
    selectedResources: state.clusters.CLUSTERS_LIST_SELECTED,
    resourcesList: state.clusters.CLUSTERS_LIST,
    sortColumn: state.clusters.CLUSTERS_LIST_SORT_COLUMN,
    sortOrder: state.clusters.CLUSTERS_LIST_SORT_ORDER,
    detailedViewList: state.clusters.CLUSTERS_VIEWMORE,
  };
};

export const getClusterTemplatesFromRedux = (
  state: State,
): SelectorReturnType => {
  return {
    currentPage: state.clustertemplates.CURRENT_PAGE,
    zonesLeft: state.clustertemplates.CLUSTER_TEMPLATES_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.clustertemplates.HIDDEN_REGIONS,
    selectedResources: state.clustertemplates.CLUSTER_TEMPLATES_LIST_SELECTED,
    resourcesList: state.clustertemplates.CLUSTER_TEMPLATES_LIST,
    sortColumn: state.clustertemplates.CLUSTER_TEMPLATES_LIST_SORT_COLUMN,
    sortOrder: state.clustertemplates.CLUSTER_TEMPLATES_LIST_SORT_ORDER,
    detailedViewList: state.clustertemplates.CLUSTER_TEMPLATES_VIEWMORE,
  };
};

export const getEndpointGroupsFromRedux = (
  state: State,
): SelectorReturnType => {
  return {
    currentPage: state.endpointgroups.CURRENT_PAGE,
    zonesLeft: state.endpointgroups.ENDPOINTGROUPS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.endpointgroups.HIDDEN_REGIONS,
    selectedResources: state.endpointgroups.ENDPOINTGROUPS_LIST_SELECTED,
    resourcesList: state.endpointgroups.ENDPOINTGROUPS_LIST,
    sortColumn: state.endpointgroups.ENDPOINTGROUPS_LIST_SORT_COLUMN,
    sortOrder: state.endpointgroups.ENDPOINTGROUPS_LIST_SORT_ORDER,
    detailedViewList: state.endpointgroups.ENDPOINTGROUPS_VIEWMORE,
  };
};

export const getFloatingIpsFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.floatingips.CURRENT_PAGE,
    zonesLeft: state.floatingips.FLOATINGIPS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.floatingips.HIDDEN_REGIONS,
    selectedResources: state.floatingips.FLOATINGIPS_LIST_SELECTED,
    resourcesList: state.floatingips.FLOATINGIPS_LIST,
    sortColumn: state.floatingips.FLOATINGIPS_LIST_SORT_COLUMN,
    sortOrder: state.floatingips.FLOATINGIPS_LIST_SORT_ORDER,
    detailedViewList: state.floatingips.FLOATINGIPS_VIEWMORE,
  };
};

export const getGardenerShootsFromRedux = (
  state: State,
): SelectorReturnType => {
  return {
    currentPage: state.gardener_shoots.CURRENT_PAGE,
    zonesLeft: state.gardener_shoots.GARDENER_SHOOTS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.gardener_shoots.HIDDEN_REGIONS,
    selectedResources: state.gardener_shoots.GARDENER_SHOOTS_LIST_SELECTED,
    resourcesList: state.gardener_shoots.GARDENER_SHOOTS_LIST,
    sortColumn: state.gardener_shoots.GARDENER_SHOOTS_LIST_SORT_COLUMN,
    sortOrder: state.gardener_shoots.GARDENER_SHOOTS_LIST_SORT_ORDER,
    detailedViewList: state.gardener_shoots.GARDENER_SHOOTS_VIEWMORE,
  };
};

export const getIkesFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.ikes.CURRENT_PAGE,
    zonesLeft: state.ikes.IKES_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.ikes.HIDDEN_REGIONS,
    selectedResources: state.ikes.IKES_LIST_SELECTED,
    resourcesList: state.ikes.IKES_LIST,
    sortColumn: state.ikes.IKES_LIST_SORT_COLUMN,
    sortOrder: state.ikes.IKES_LIST_SORT_ORDER,
    detailedViewList: state.ikes.IKES_VIEWMORE,
  };
};

export const getIpsecsFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.ipsecs.CURRENT_PAGE,
    zonesLeft: state.ipsecs.IPSECS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.ipsecs.HIDDEN_REGIONS,
    selectedResources: state.ipsecs.IPSECS_LIST_SELECTED,
    resourcesList: state.ipsecs.IPSECS_LIST,
    sortColumn: state.ipsecs.IPSECS_LIST_SORT_COLUMN,
    sortOrder: state.ipsecs.IPSECS_LIST_SORT_ORDER,
    detailedViewList: state.ipsecs.IPSECS_VIEWMORE,
  };
};

export const getKeypairsFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.keypairs.CURRENT_PAGE,
    zonesLeft: state.keypairs.KEYPAIRS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.keypairs.HIDDEN_REGIONS,
    selectedResources: state.keypairs.KEYPAIRS_LIST_SELECTED,
    resourcesList: state.keypairs.KEYPAIRS_LIST,
    sortColumn: state.keypairs.KEYPAIRS_LIST_SORT_COLUMN,
    sortOrder: state.keypairs.KEYPAIRS_LIST_SORT_ORDER,
    detailedViewList: state.keypairs.KEYPAIRS_VIEWMORE,
  };
};

export const getLoadBalancersFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.loadbalancers.CURRENT_PAGE,
    zonesLeft: state.loadbalancers.LOADBALANCERS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.loadbalancers.HIDDEN_REGIONS,
    selectedResources: state.loadbalancers.LOADBALANCERS_LIST_SELECTED,
    resourcesList: state.loadbalancers.LOADBALANCERS_LIST,
    sortColumn: state.loadbalancers.LOADBALANCERS_LIST_SORT_COLUMN,
    sortOrder: state.loadbalancers.LOADBALANCERS_LIST_SORT_ORDER,
    detailedViewList: state.loadbalancers.LOADBALANCERS_VIEWMORE,
  };
};

export const getMonitoringContactsFromRedux = (
  state: State,
): SelectorReturnType => {
  return {
    currentPage: state.monitoring_contacts.CURRENT_PAGE,
    zonesLeft:
      state.monitoring_contacts.MONITORING_CONTACTS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.monitoring_contacts.HIDDEN_REGIONS,
    selectedResources:
      state.monitoring_contacts.MONITORING_CONTACTS_LIST_SELECTED,
    resourcesList: state.monitoring_contacts.MONITORING_CONTACTS_LIST,
    sortColumn: state.monitoring_contacts.MONITORING_CONTACTS_LIST_SORT_COLUMN,
    sortOrder: state.monitoring_contacts.MONITORING_CONTACTS_LIST_SORT_ORDER,
    detailedViewList: state.monitoring_contacts.MONITORING_CONTACTS_VIEWMORE,
  };
};

export const getMonitoringSchedulesFromRedux = (
  state: State,
): SelectorReturnType => {
  return {
    currentPage: state.monitoring_schedules.CURRENT_PAGE,
    zonesLeft:
      state.monitoring_schedules.MONITORING_SCHEDULES_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.monitoring_schedules.HIDDEN_REGIONS,
    selectedResources:
      state.monitoring_schedules.MONITORING_SCHEDULES_LIST_SELECTED,
    resourcesList: state.monitoring_schedules.MONITORING_SCHEDULES_LIST,
    sortColumn:
      state.monitoring_schedules.MONITORING_SCHEDULES_LIST_SORT_COLUMN,
    sortOrder: state.monitoring_schedules.MONITORING_SCHEDULES_LIST_SORT_ORDER,
    detailedViewList: state.monitoring_schedules.MONITORING_SCHEDULES_VIEWMORE,
  };
};

export const getNetworksFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.networks.CURRENT_PAGE,
    zonesLeft: state.networks.NETWORKS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.networks.HIDDEN_REGIONS,
    selectedResources: state.networks.NETWORKS_LIST_SELECTED,
    resourcesList: state.networks.NETWORKS_LIST,
    sortColumn: state.networks.NETWORKS_LIST_SORT_COLUMN,
    sortOrder: state.networks.NETWORKS_LIST_SORT_ORDER,
    detailedViewList: state.networks.NETWORKS_VIEWMORE,
  };
};

export const getOpenstackUsersFromRedux = (
  state: State,
): SelectorReturnType => {
  return {
    currentPage: state.openstack_users.CURRENT_PAGE,
    zonesLeft: state.openstack_users.OPENSTACK_USERS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.openstack_users.HIDDEN_REGIONS,
    selectedResources: state.openstack_users.OPENSTACK_USERS_LIST_SELECTED,
    resourcesList: state.openstack_users.OPENSTACK_USERS_LIST,
    sortColumn: state.openstack_users.OPENSTACK_USERS_LIST_SORT_COLUMN,
    sortOrder: state.openstack_users.OPENSTACK_USERS_LIST_SORT_ORDER,
    detailedViewList: state.openstack_users.OPENSTACK_USERS_VIEWMORE,
  };
};

export const getOrchestrationStacksFromRedux = (
  state: State,
): SelectorReturnType => {
  return {
    currentPage: state.orchestration_stacks.CURRENT_PAGE,
    zonesLeft:
      state.orchestration_stacks.ORCHESTRATION_STACKS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.orchestration_stacks.HIDDEN_REGIONS,
    selectedResources:
      state.orchestration_stacks.ORCHESTRATION_STACKS_LIST_SELECTED,
    resourcesList: state.orchestration_stacks.ORCHESTRATION_STACKS_LIST,
    sortColumn:
      state.orchestration_stacks.ORCHESTRATION_STACKS_LIST_SORT_COLUMN,
    sortOrder: state.orchestration_stacks.ORCHESTRATION_STACKS_LIST_SORT_ORDER,
    detailedViewList: state.orchestration_stacks.ORCHESTRATION_STACKS_VIEWMORE,
  };
};

export const getPortsFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.ports.CURRENT_PAGE,
    zonesLeft: state.ports.PORTS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.ports.HIDDEN_REGIONS,
    selectedResources: state.ports.PORTS_LIST_SELECTED,
    resourcesList: state.ports.PORTS_LIST,
    sortColumn: state.ports.PORTS_LIST_SORT_COLUMN,
    sortOrder: state.ports.PORTS_LIST_SORT_ORDER,
    detailedViewList: state.ports.PORTS_VIEWMORE,
  };
};

export const getPrivateImagesFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.privateimages.CURRENT_PAGE,
    zonesLeft: state.privateimages.IMAGES_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.privateimages.HIDDEN_REGIONS,
    selectedResources: state.privateimages.IMAGES_LIST_SELECTED,
    resourcesList: {
      ...state.privateimages.IMAGES_LIST,
      ...state.privateimages.PENDINGIMAGES_LIST,
    },
    sortColumn: state.privateimages.IMAGES_LIST_SORT_COLUMN,
    sortOrder: state.privateimages.IMAGES_LIST_SORT_ORDER,
    detailedViewList: state.privateimages.IMAGES_VIEWMORE,
  };
};

export const getProjectsFromRedux = (
  state: State,
): SelectorReturnType & {
  currentProjectName: string | null;
  resourcesList: { [key: string]: MergedProjectsById };
} => {
  return {
    currentPage: state.projects.CURRENT_PAGE,
    zonesLeft: 0,
    hiddenRegions: [],
    selectedResources: [],
    resourcesList: state.projects.list,
    sortColumn: "",
    sortOrder: "",
    currentProjectName: state.projects.currentProjectName || "",
    detailedViewList: state.projects.PROJECTS_VIEWMORE,
  };
};

export const getPublicImagesFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.publicimages.CURRENT_PAGE,
    zonesLeft: state.publicimages.IMAGES_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.publicimages.HIDDEN_REGIONS,
    selectedResources: state.publicimages.IMAGES_LIST_SELECTED,
    resourcesList: state.publicimages.IMAGES_LIST,
    sortColumn: state.publicimages.IMAGES_LIST_SORT_COLUMN,
    sortOrder: state.publicimages.IMAGES_LIST_SORT_ORDER,
    detailedViewList: state.publicimages.IMAGES_VIEWMORE,
  };
};

export const getRoutersFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.routers.CURRENT_PAGE,
    zonesLeft: state.routers.ROUTERS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.routers.HIDDEN_REGIONS,
    selectedResources: state.routers.ROUTERS_LIST_SELECTED,
    resourcesList: state.routers.ROUTERS_LIST,
    sortColumn: state.routers.ROUTERS_LIST_SORT_COLUMN,
    sortOrder: state.routers.ROUTERS_LIST_SORT_ORDER,
    detailedViewList: state.routers.ROUTERS_VIEWMORE,
  };
};

export const getSecurityGroupsFromRedux = (
  state: State,
): SelectorReturnType => {
  return {
    currentPage: state.securitygroups.CURRENT_PAGE,
    zonesLeft: state.securitygroups.SECURITYGROUPS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.securitygroups.HIDDEN_REGIONS,
    selectedResources: state.securitygroups.SECURITYGROUPS_LIST_SELECTED,
    resourcesList: state.securitygroups.SECURITYGROUPS_LIST,
    sortColumn: state.securitygroups.SECURITYGROUPS_LIST_SORT_COLUMN,
    sortOrder: state.securitygroups.SECURITYGROUPS_LIST_SORT_ORDER,
    detailedViewList: state.securitygroups.SECURITYGROUPS_VIEWMORE,
  };
};

export const getServerSnapshotsFromRedux = (
  state: State,
): SelectorReturnType => {
  return {
    currentPage: state.server_snapshots.CURRENT_PAGE,
    zonesLeft: state.server_snapshots.SERVER_SNAPSHOTS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.server_snapshots.HIDDEN_REGIONS,
    selectedResources: state.server_snapshots.SERVER_SNAPSHOTS_LIST_SELECTED,
    resourcesList: state.server_snapshots.SERVER_SNAPSHOTS_LIST,
    sortColumn: state.server_snapshots.SERVER_SNAPSHOTS_LIST_SORT_COLUMN,
    sortOrder: state.server_snapshots.SERVER_SNAPSHOTS_LIST_SORT_ORDER,
    detailedViewList: state.server_snapshots.SERVER_SNAPSHOTS_VIEWMORE,
  };
};

export const getServersFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.servers.CURRENT_PAGE,
    zonesLeft: state.servers.SERVERS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.servers.HIDDEN_REGIONS,
    selectedResources: state.servers.SERVERS_LIST_SELECTED,
    resourcesList: state.servers.SERVERS_LIST,
    sortColumn: state.servers.SERVERS_LIST_SORT_COLUMN,
    sortOrder: state.servers.SERVERS_LIST_SORT_ORDER,
    detailedViewList: state.servers.SERVERS_VIEWMORE,
  };
};

export const getSubnetsFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.subnets.CURRENT_PAGE,
    zonesLeft: state.subnets.SUBNETS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.subnets.HIDDEN_REGIONS,
    selectedResources: state.subnets.SUBNETS_LIST_SELECTED,
    resourcesList: state.subnets.SUBNETS_LIST,
    sortColumn: state.subnets.SUBNETS_LIST_SORT_COLUMN,
    sortOrder: state.subnets.SUBNETS_LIST_SORT_ORDER,
    detailedViewList: state.subnets.SUBNETS_VIEWMORE,
  };
};

export const getVolumesFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.volumes.CURRENT_PAGE,
    zonesLeft: state.volumes.VOLUMES_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.volumes.HIDDEN_REGIONS,
    selectedResources: state.volumes.VOLUMES_LIST_SELECTED,
    resourcesList: state.volumes.VOLUMES_LIST,
    sortColumn: state.volumes.VOLUMES_LIST_SORT_COLUMN,
    sortOrder: state.volumes.VOLUMES_LIST_SORT_ORDER,
    detailedViewList: state.volumes.VOLUMES_VIEWMORE,
  };
};

export const getVolumeSnapshotsFromRedux = (
  state: State,
): SelectorReturnType => {
  return {
    currentPage: state.volume_snapshots.CURRENT_PAGE,
    zonesLeft: state.volume_snapshots.VOLUME_SNAPSHOTS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.volume_snapshots.HIDDEN_REGIONS,
    selectedResources: state.volume_snapshots.VOLUME_SNAPSHOTS_LIST_SELECTED,
    resourcesList: state.volume_snapshots.VOLUME_SNAPSHOTS_LIST,
    sortColumn: state.volume_snapshots.VOLUME_SNAPSHOTS_LIST_SORT_COLUMN,
    sortOrder: state.volume_snapshots.VOLUME_SNAPSHOTS_LIST_SORT_ORDER,
    detailedViewList: state.volume_snapshots.VOLUME_SNAPSHOTS_VIEWMORE,
  };
};

export const getVPNsFromRedux = (state: State): SelectorReturnType => {
  return {
    currentPage: state.vpns.CURRENT_PAGE,
    zonesLeft: state.vpns.VPNS_LIST_LOADING_ZONES_LEFT,
    hiddenRegions: state.vpns.HIDDEN_REGIONS,
    selectedResources: state.vpns.VPNS_LIST_SELECTED,
    resourcesList: state.vpns.VPNS_LIST,
    sortColumn: state.vpns.VPNS_LIST_SORT_COLUMN,
    sortOrder: state.vpns.VPNS_LIST_SORT_ORDER,
    detailedViewList: state.vpns.VPNS_VIEWMORE,
  };
};

export const mapResourceNameToReduxSelector = {
  cleura_users: getCleuraUsersFromRedux,
  clusters: getClustersFromRedux,
  clustertemplates: getClusterTemplatesFromRedux,
  endpointgroups: getEndpointGroupsFromRedux,
  floatingips: getFloatingIpsFromRedux,
  gardener_shoots: getGardenerShootsFromRedux,
  ikes: getIkesFromRedux,
  ipsecs: getIpsecsFromRedux,
  keypairs: getKeypairsFromRedux,
  loadbalancers: getLoadBalancersFromRedux,
  monitoring_contacts: getMonitoringContactsFromRedux,
  monitoring_schedules: getMonitoringSchedulesFromRedux,
  networks: getNetworksFromRedux,
  openstack_users: getOpenstackUsersFromRedux,
  orchestration_stacks: getOrchestrationStacksFromRedux,
  ports: getPortsFromRedux,
  privateimages: getPrivateImagesFromRedux,
  projects: getProjectsFromRedux,
  publicimages: getPublicImagesFromRedux,
  routers: getRoutersFromRedux,
  securitygroups: getSecurityGroupsFromRedux,
  server_snapshots: getServerSnapshotsFromRedux,
  servers: getServersFromRedux,
  subnets: getSubnetsFromRedux,
  volumes: getVolumesFromRedux,
  volume_snapshots: getVolumeSnapshotsFromRedux,
  vpns: getVPNsFromRedux,
};
