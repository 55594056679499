import { useSelector } from "react-redux";
import DashboardWidget from "../components/shared/dashboardWidget/DashboardWidget";
import useSubscribe from "../custom-hooks/useSubscribe";
import { mapResourceNameToReduxSelector } from "../selectors/resources";
import { valuesOf } from "../shared-functions/objects";
import { getGardenerState } from "../gardener/reducer/selectors";
import { Icon, Popup } from "semantic-ui-react";
import { ShootType } from "../gardener/types";

type AccumulatedStats = {
  normal: number;
  processing: number;
  error: number;
};

const Title = () => {
  return <h2>Gardener Shoots</h2>;
};

const SeeMore = () => {
  return (
    <div className="text-center">
      See more information at
      <a
        href="/containers/gardener"
        className="padding-left-half padding-right-half"
        rel="noopener noreferrer"
      >
        Gardener Service
      </a>
      page.
    </div>
  );
};

const RenderStats = ({ stats }: { stats: AccumulatedStats }) => {
  const { normal, processing, error } = stats;

  if (normal === 0 && processing === 0 && error === 0) {
    return (
      <div className="flex hcenter vcenter flex-1 flex-column ">
        <div className="flex">
          <Icon
            name="exclamation circle"
            className="margin-right-half color-red font-XL"
          />
          No shoots are created.
        </div>
      </div>
    );
  }

  return (
    <div className="flex hcenter  flex-1 flex-column margin-left-20">
      {normal > 0 && (
        <div className="flex margin-bottom">
          <Icon
            name="check circle"
            className="margin-right color-green font-XL"
          />
          <span>
            {normal} Shoot{normal > 1 && "s"} performing normally.
          </span>
        </div>
      )}
      {processing > 0 && (
        <div className="flex margin-bottom">
          <Icon
            name="warning circle"
            className="margin-right color-orange font-XL"
          />
          <span>
            {processing} Shoot{processing > 1 && "s"} being updated.
          </span>
        </div>
      )}
      {error > 0 && (
        <div className="flex margin-bottom">
          <Icon
            name="times circle"
            className="margin-right color-red font-XL"
          />
          <span>
            {error} Shoot{error > 1 && "s"} with issues.
          </span>
          <Popup
            position="top center"
            trigger={
              <Icon
                name="question circle"
                className="padding-left-half color-disable"
              />
            }
            content="Includes stopped, hibernated, and shoots with errors."
          />
        </div>
      )}
    </div>
  );
};

const groupShootsByStats = (shoots: ShootType[]): AccumulatedStats => {
  const accumulatedStats: AccumulatedStats = {
    normal: 0,
    processing: 0,
    error: 0, // Includes stopped, hibernated, and those with errors
  };

  shoots.forEach((shoot) => {
    const { status, task_state } = shoot;

    if (task_state) {
      // Shoots with a task state are always "processing"
      accumulatedStats.processing++;
      return;
    }

    if (typeof status === "object") {
      const { constraints, hibernated, lastOperation, lastErrors } = status;

      if (
        (constraints || []).some(
          (item) => item.codes || item.status === "False",
        )
      ) {
        // Shoots with failing constraints are considered "error"
        accumulatedStats.error++;
        return;
      }

      if (hibernated) {
        // Hibernated shoots are either "processing" or "error"
        if (lastOperation?.state === "Processing") {
          accumulatedStats.processing++;
        } else {
          accumulatedStats.error++;
        }
        return;
      }

      if (hibernated === false && lastOperation?.state === "Succeeded") {
        // Active shoots that succeeded
        accumulatedStats.normal++;
        return;
      }

      if (lastOperation?.state === "Processing") {
        // Shoots currently being processed
        accumulatedStats.processing++;
        return;
      }

      if (lastErrors) {
        // Shoots with errors
        accumulatedStats.error++;
        return;
      }
    }

    // Default to "error" if no specific conditions are met
    accumulatedStats.error++;
  });

  return accumulatedStats;
};

export default function GardenerWidget() {
  const gardenState = useSelector(getGardenerState);
  const isUnavailable =
    !gardenState?.identifiers?.[0]?.regionTag ||
    gardenState?.identifiers?.[0]?.status === "closed";

  useSubscribe(isUnavailable ? null : "GARDENER_SHOOTS_LIST");

  const { resourcesList: shootsList, zonesLeft } = useSelector(
    mapResourceNameToReduxSelector["gardener_shoots"],
  );

  const shootStats = groupShootsByStats(valuesOf(shootsList));

  if (isUnavailable) {
    return (
      <DashboardWidget className="" loading={false}>
        <Title />
        <div className="width-100p flex flex-column vcenter hcenter">
          <div className="flex vcenter flex-1 line-height-fix">
            <Icon
              name="times circle"
              className="margin-right-half color-red font-XL"
            />
            <span className="color-black">
              Gardener service is not available!
            </span>
          </div>
          <SeeMore />
        </div>
      </DashboardWidget>
    );
  }

  if (zonesLeft > 0) {
    return (
      <DashboardWidget className="" loading={true}>
        <Title />
        <div className="width-100p flex flex-column vcenter hcenter"></div>
      </DashboardWidget>
    );
  }

  return (
    <DashboardWidget className="" loading={false}>
      <Title />
      <div className="width-100p flex flex-column ">
        <RenderStats stats={shootStats} />

        <SeeMore />
      </div>
    </DashboardWidget>
  );
}
