import { defaultValues } from "../app_constants";

/**
 * This will return the city (location) of the user's browser
 * NOTE: If user has disabled GPS or have third party app to change the timezone, this may not work properly
 * @returns either the user city location or an empty string
 */
export const getUserCityLocation = () => {
  if (!Intl) return "";

  let zone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || "";
  if (zone) {
    zone = zone?.split("/")?.[1] || "";
  }
  return zone;
};

/**
 * This will randomize a value to start the maintenance window start time
 * @returns //one of :  0,1,2,3,4,5,22,23
 */
export const getRandomStartTime = () =>
  (Math.floor(Math.random() * 8) + 22) % 24;

/**
 * This will take a start time and returns an end time
 * @param {*} begin //one of :  0,1,2,3,4,5,22,23
 * @returns //one of :  0,1,2,...7
 */
export const getRandomEndTime = (begin: number) => (begin + 2) % 24; //one of : 0,1,2,...7

/**
 * @param {number} h can be a number between 0-59
 */
export const convertToMilitary = (h: number) => {
  return (h > 9 ? "" : "0") + h;
};

/**
 * Gets hour, minute and list of days and convert it to a cron time
 * https://en.wikipedia.org/wiki/Cron
 * @param {*} hh
 * @param {*} mm
 * @param {*} days
 * @returns
 */
export const createCronTime = (hh: string, mm: string, days: string[]) => {
  const daysIndex = days
    .map((day) => defaultValues.days.indexOf(day))
    .join(",");

  return `${mm} ${hh} * * ${daysIndex}`;
};

/**
 * This function gets a cron-time string and returns list of days and hours and minutes in an array
 * https://en.wikipedia.org/wiki/Cron
 * @param {*} a "45 14 * * 2,5"
 * @returns [['Tuesday','Friday'] ,14 ,45]
 */
export const destructureCronTime = (a: string) => {
  const mm = a.substring(0, 2);
  const hh = a.substring(3, 5);

  const d = a.split("* ");
  let destructedTime: string[] = [];

  if (d[d.length - 1]) {
    destructedTime = d[d.length - 1]
      .split(",")
      .map((x) => Number(x))
      .map((x) => defaultValues.days[x]);
  }

  return [destructedTime, hh, mm];
};

/**
 * Gets a time in milliseconds and returns a human readable string consists of months, days, minutes, seconds (if they are not zero)
 * @param {Number} milliseconds
 * @returns {String} 'X months, Y minutes' is a valid return string
 */
export const millisecondsToTimeChunks = (milliseconds: number) => {
  const include = (name: string, num: number) =>
    `${num} ${name}${num > 1 ? "s" : ""}, `;

  const second = milliseconds % 60;
  const minute = Math.floor((milliseconds / 60) % 60);
  const hour = Math.floor((milliseconds / 60 / 60) % 24);
  const day = Math.floor((milliseconds / 60 / 60 / 24) % 30);
  const month = Math.floor(milliseconds / 60 / 60 / 24 / 30);

  let duration = "";

  if (month) {
    duration += include("month", month);
  }
  if (day) {
    duration += include("day", day);
  }
  if (hour) {
    duration += include("hour", hour);
  }
  if (minute) {
    duration += include("minute", minute);
  }
  if (second) {
    duration += include("second", second);
  }

  if (duration) {
    duration = duration.slice(0, -2);
  }

  return duration;
};

/**
 * Gets a date in string format in the form of => YYYY-MM-DD HH:MM:SS (2022-02-12 05:22:28)
 * @param {String} dateString
 * @returns {Object}
 */
const getMinimumDate = (dateString: string) => {
  return {
    year: Number(dateString.split("-")[0]),
    month: Number(dateString.split("-")[1]),
    day: Number(dateString.split("-")[2].slice(0, 2)),
  };
};

/**
 * @param {Date} date object
 * @returns {Object}
 */
const getMaximumDate = () => {
  const now = new Date();

  return {
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    day: now.getDate(),
  };
};

/**
 * Returns the last week object in the form of an object
 * @returns {Object}
 */
const getLastWeekDate = () => {
  let lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);

  return {
    year: lastWeek.getFullYear(),
    month: lastWeek.getMonth() + 1,
    day: lastWeek.getDate(),
  };
};

/**
 * Takes a date in string format YYYY-MM-DD HH:MM:SS (2022-02-12 05:22:28)
 * and returns a big object that includes different time objects
 * @param {Object} dateString
 * @returns {Object}
 */
export const getInitialScheduleInterval = (dateString: string) => {
  const minimumDate = getMinimumDate(dateString);

  const maximumDate = getMaximumDate();

  const lastWeek = getLastWeekDate();

  const range = {
    from: lastWeek,
    to: maximumDate,
  };

  return {
    range,
    maximumDate,
    minimumDate,
  };
};

export const getTimeFromDate = (date: Date) => {
  return `${("0" + date.getHours()).slice(-2)}:${(
    "0" + date.getMinutes()
  ).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}`;
};

/**
 * Expects an string in form of ISO 8601 : "2024-05-16T07:29:47Z"
 * and returns an string in human readable format
 * @param {*} input : string
 * @returns : string
 */
export const toHumanReadableDate = (input: string) => {
  // Regular expression to match ISO 8601 input strings
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

  if (iso8601Regex.test(input)) {
    let date = new Date(input);

    let dateOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    let timeOptions: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    let formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(
      date,
    ); // Something like: May 16, 2024
    let formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(
      date,
    ); // Something like: 9:29:59 AM

    return `${formattedDate}, ${formattedTime}`;
  }

  return input;
};

/**
 * Helper function to format date as "YYYY-MM-DD HH:mm"
 * @param {}
 * @returns : string
 */
const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

/**
 * Helper function to create time span from 24 hours ago upto now
 * @param {} 
 * @returns : timespan: {
      from: formatted 24h Ago, //2024-12-01 14:44
      to: formatted Now, //2024-12-02 14:44
    },
 */
export const timeSpanLast24Hours = () => {
  const now = new Date();
  const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

  const formattedNow = formatDate(now);
  const formattedTwentyFourHoursAgo = formatDate(twentyFourHoursAgo);

  return {
    timespan: {
      from: formattedTwentyFourHoursAgo,
      to: formattedNow,
    },
  };
};
