import HamburgerMenu from "../../components/shared/hamburger-menu/HamburgerMenu";
import {
  getPrivileges,
  isResourceCreating,
  toastError,
} from "../../app_shared_functions";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useRef, useState } from "react";
import { InvoiceItem } from "./type";
import FetchAPI from "../../api/FetchAPI";
import { HamburgerItemType } from "../../components/shared/hamburger-menu/types";
import { defaultValues } from "../../app_constants";
import { safeToLowerCase } from "../../shared-functions/string";
import { Header, Icon, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";
import Payment from "./payment/Payment";
import { State } from "../../selectors/state";

enum PDFActionType {
  View = "view",
  Download = "download",
  Raw = "raw",
  Pay = "pay",
}

const InvoiceMenu = ({ resource }: { resource: InvoiceItem }) => {
  const pdfViewRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState<PDFActionType | null>(null);

  const { t } = useTranslation();

  const login = useSelector((state: State) => state.login);
  const privileges = getPrivileges(login, "invoice");
  const payInvoice = useCallback(() => setAction(PDFActionType.Pay), []);

  const loadPDF = useCallback(
    (actionType: PDFActionType) => {
      setLoading(true);
      setAction(actionType);

      FetchAPI.Account.getInvoicePDF(resource.id)
        .then((response) => {
          switch (actionType) {
            case PDFActionType.View: {
              let obj = document.createElement("object");
              obj.style.width = "100%";
              obj.style.height = "842pt";
              obj.type = "application/pdf";
              obj.data = "data:application/pdf;base64," + response.data;
              if (pdfViewRef.current) {
                pdfViewRef.current.innerHTML = "";
                pdfViewRef.current.appendChild(obj);
              }
              break;
            }

            case PDFActionType.Download: {
              let link = document.createElement("a");
              link.innerHTML = "";
              link.download = `${resource.id}.pdf`;
              link.href =
                "data:application/octet-stream;base64," + response.data;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              break;
            }

            case PDFActionType.Raw: {
              if (!navigator.clipboard.writeText) {
                toast.warn(
                  "Browser does not support copying text to the clipboard.",
                );
                break;
              }

              if (typeof response.data !== "string") {
                toast.warn(
                  "Unable to copy raw data to clipboard. Data format is invalid.",
                );
                break;
              }

              navigator.clipboard.writeText(response.data);
              toast.info("Raw data is copied to clipboard.");
              break;
            }
            default:
              break;
          }
        })
        .catch((error) => {
          toastError(error, "Error fetching PDF data");
        })
        .finally(() => setLoading(false));
    },
    [resource.id],
  );

  const viewInvoice = useCallback(() => loadPDF(PDFActionType.View), [loadPDF]);
  const rawInvoice = useCallback(() => loadPDF(PDFActionType.Raw), [loadPDF]);
  const downloadInvoice = useCallback(
    () => loadPDF(PDFActionType.Download),
    [loadPDF],
  );

  const resetAction = useCallback(() => setAction(null), []);

  const items = useMemo(() => {
    const menuItems: HamburgerItemType[] = [];

    if (loading) {
      menuItems.push({
        icon: "spinner loading",
        title: t(`invoices.actions.viewpdf`),
      });
      menuItems.push({
        icon: "spinner loading",
        title: t(`invoices.actions.downloadpdf`),
      });
      menuItems.push({
        icon: "spinner loading",
        title: t(`invoices.actions.rawdata`),
      });
    } else {
      menuItems.push({
        icon: "eye",
        action: viewInvoice,
        title: t(`invoices.actions.viewpdf`),
      });
      menuItems.push({
        icon: "download",
        action: downloadInvoice,
        title: t(`invoices.actions.downloadpdf`),
      });
      menuItems.push({
        icon: "file alternate outline",
        action: rawInvoice,
        title: t(`invoices.actions.rawdata`),
      });
    }

    if (
      privileges === "full" &&
      defaultValues?.usersettings?.invoice_payable_status?.includes(
        safeToLowerCase(resource.status),
      )
    ) {
      menuItems.push({
        icon: "dollar sign",
        action: payInvoice,
        title: t(`invoices.actions.pay`),
        isDanger: true,
      });
    }

    return menuItems;
  }, [
    t,
    loading,
    downloadInvoice,
    rawInvoice,
    viewInvoice,
    payInvoice,
    privileges,
    resource.status,
  ]);

  return (
    <>
      <HamburgerMenu
        isCreating={isResourceCreating(resource.status)}
        items={items}
      />

      {/* View PDF */}
      <Modal
        size="large"
        open={action === PDFActionType.View}
        centered={true}
        onClose={resetAction}
      >
        <Header
          className="modal-header"
          icon="file pdf"
          content="Invoice Receipt"
        />
        <Modal.Content className="padding-left-00 padding-top-00 padding-bottom-00 padding-right-00 overflow-visible">
          {loading && (
            <div className="padding-top-50 padding-bottom-50 padding-right-50 padding-left-50">
              <Icon name="spinner" loading className="margin-right-half" />
              Loading
            </div>
          )}
          <div ref={pdfViewRef} />
        </Modal.Content>
      </Modal>

      {/* Payment */}
      {action === PDFActionType.Pay && (
        <Payment
          invoice={resource}
          open={action === PDFActionType.Pay}
          close_pay_modal={resetAction}
        />
      )}
    </>
  );
};

export default InvoiceMenu;
