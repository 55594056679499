import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSubscription, removeSubscription } from "../actions/connectivity";
import { checkMissingArrayEntries } from "../app_shared_functions";
import { State } from "../selectors/state";

const useSubscribe = (items: string | string[] | null) => {
  const dispatch = useDispatch();

  const activeSubscriptions = useSelector(
    (state: State) => state.connectivity.activeSubscriptions,
  );

  useEffect(() => {
    if (!items) return;
    const list = Array.isArray(items) ? items : [items];

    const subscriptions: string[] = checkMissingArrayEntries(
      activeSubscriptions,
      list,
    );
    subscriptions.forEach((x) => dispatch(addSubscription(x)));
    return () => {
      subscriptions.forEach((x) => dispatch(removeSubscription(x)));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- No need to include activeSubscriptions as dependency since it will always be a new array sent from redux causing this function to run infinitely
  }, [dispatch, items]);
};

export default useSubscribe;
