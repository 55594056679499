import React from "react";
import { Loader, Segment } from "semantic-ui-react";
import FetchAPI from "../../../api/FetchAPI";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { convertTimestampToDate } from "../../../app_shared_functions";
import { defaultValues } from "../../../app_constants";
import { timeSpanLast24Hours } from "../../../shared-functions/datetime";

class LoadTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    const objectToSend = timeSpanLast24Hours();

    FetchAPI.Monitoring.Summary.getLoadtimes(objectToSend)
      .then((res) => {
        this.setState({
          loadTime: res?.data?.data?.map((x) => [
            Number(x.timestamp) * 1000,
            Number(x.avg_loadtime),
          ]),
        });
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const highchartData = {
      title: {
        ...defaultValues.charts_default.title.style,
        text: " ",
      },
      subtitle: {
        ...defaultValues.charts_default.subtitle,
      },
      xAxis: {
        ...defaultValues.charts_default.xAxis,
      },
      yAxis: {
        title: {
          text: "AVG Loading time in milliseconds",
        },
        min: 0,
      },
      chart: {
        ...defaultValues.charts_default.chart,
      },
      legend: {
        ...defaultValues.charts_default.legend,
      },
      credits: {
        ...defaultValues.charts_default.credits,
      },
      tooltip: {
        ...defaultValues.charts_default.tooltip,
        formatter: function () {
          const p = this.points[0];
          return `<span style="font-size:10px"> ${convertTimestampToDate(
            p.x,
          )} </span><br/><b>Avg. loadtime (ms): ${p.y}</b>`;
        },
      },
      series: [
        {
          data: this.state?.loadTime || [],
        },
      ],
    };

    return (
      <div className={`dashboard-widget`}>
        <Segment className="flex">
          <h2>Average Loadtime</h2>

          <div className="content-wrapper width-100p margin-top">
            <HighchartsReact
              highcharts={Highcharts}
              options={highchartData}
              containerProps={{ className: "chart-container" }}
            />

            {this.state.loading && <Loader active></Loader>}
          </div>
        </Segment>
      </div>
    );
  }
}

export default LoadTime;
