import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGardenerDomain } from "./reducer/selectors";
import { canLoadMore } from "../app_shared_functions";
import FetchAPI from "../api/FetchAPI";
import SortedGardenerList from "./SortedGardenerList";
import MultiActions from "./MultiActions";
import { Sidebar, Segment, Icon, Input } from "semantic-ui-react";
import { PageToolbar, PageToolbarButtonsPane } from "../components/PageToolbar";
import Breadcrumbs from "../components/shared/breadcrumbs/Breadcrumbs";
import LoadMoreSensor from "../components/shared/LoadMoreSensor";
import { toggleSlidingMenu } from "../actions/toggleSlidingMenu";
import { showMoreResources } from "../actions/shared";
import { filterNotDeprecatedKubeVersions } from "./helpers/kubeversions";
import AboutGardener from "./AboutGardener";
import useWebsocketErrors from "../custom-hooks/useWebsocketErrors";
import { debounceInput } from "../shared-functions/environment";
import RegionsLoadingSpinner from "../components/shared/RegionsLoadingSpinner";
import GridContext from "../components/shared/grid-bits/context/GridContext";
import { mapResourceTypeToReduxList } from "../components/shared/grid-bits/mapResourceTypeToReduxList";
import useSubscribe from "../custom-hooks/useSubscribe";
import useHasCRUDAccess from "../custom-hooks/useHasCRUDAccess";
import { mapResourceNameToReduxSelector } from "../selectors/resources";
import { valuesOf } from "../shared-functions/objects";
import {
  CloudProfileApiResponse,
  CloudProfileData,
  TKubeVersion,
} from "./types";
import CloudProfileContext from "./context/CloudProfileContext";
import { State } from "../selectors/state";

const GardenerList = () => {
  const resourceType: keyof typeof mapResourceTypeToReduxList =
    "gardener_shoots";

  const dispatch = useDispatch();

  useSubscribe(mapResourceTypeToReduxList[resourceType]);

  const hasCRUDAccess = useHasCRUDAccess("openstack");

  const {
    resourcesList: shootsList,
    currentPage,
    zonesLeft,
    hiddenRegions,
  } = useSelector(mapResourceNameToReduxSelector[resourceType]);

  const websocketErrors = useSelector(
    (state: State) => state.gardener_shoots.GARDENER_SHOOTS_LIST_DATA_ERROR,
  );
  useWebsocketErrors(websocketErrors);

  const gardenDomain = useSelector(getGardenerDomain);

  const breadcrumbs = [
    {
      title: "menu.containers.gardener",
    },
    {
      title: "gardener.shoots.shoot_clusters",
      popup: { Shoots: valuesOf(shootsList).length },
    },
  ];

  const [filteringText, setFilteringText] = useState("");
  const [loadingMore, setLoadingMore] = useState(false);
  const [cloudProfile, setCloudProfile] = useState<{
    kubePatches: TKubeVersion[];
    machineTypes: string[];
    machineImages: string[];
  }>({
    kubePatches: [],
    machineTypes: [],
    machineImages: [],
  });

  const onCreateShoot = useCallback(() => {
    dispatch(toggleSlidingMenu("create", "Gardener Shoot Cluster"));
  }, [dispatch]);

  const checkAndLoadMore = useCallback(
    (isVisible) => {
      if (isVisible && canLoadMore(shootsList, currentPage, hiddenRegions)) {
        setLoadingMore(true);
        dispatch(showMoreResources("gardener shoots"));
      }
    },
    [hiddenRegions, currentPage, dispatch, shootsList],
  );

  useEffect(() => {
    setLoadingMore(false);
  }, [currentPage]);

  /* Get Not-Deprecated Kubernetes Patches */
  useEffect(() => {
    const responsePromise =
      FetchAPI.Gardener.CloudProfile.getList(gardenDomain);

    responsePromise.then((res) => {
      const typedRes = res as CloudProfileApiResponse<CloudProfileData>;
      setCloudProfile({
        kubePatches: filterNotDeprecatedKubeVersions(
          typedRes.data[0].spec.kubernetes.versions || [],
        ),
        machineTypes: typedRes.data[0].spec.machineTypes || [],
        machineImages: typedRes.data[0].spec.machineImages || [],
      });
    });
  }, [gardenDomain]);

  const onFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      return debounceInput(() => setFilteringText(val))();
    },
    [],
  );

  const gridContextValues = useMemo(() => {
    return {
      filteringText,
      resourceType,
      hasCRUDAccess,
    };
  }, [resourceType, hasCRUDAccess, filteringText]);

  return (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar.Pusher>
          <PageToolbar>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <PageToolbarButtonsPane>
              <button
                className="button button--transparent"
                onClick={onCreateShoot}
              >
                Create kubernetes cluster
                <Icon name="plus circle" />
              </button>
            </PageToolbarButtonsPane>

            <AboutGardener />
          </PageToolbar>

          <div className="page-content">
            <div className="top-section">
              <MultiActions />
              <div className="pos_right">
                <div className="ui input input-white">
                  <Input
                    minLength={2}
                    onChange={onFilterChange}
                    placeholder="Enter filter text..."
                  />
                </div>
              </div>
            </div>
            <GridContext.Provider value={gridContextValues}>
              <CloudProfileContext.Provider value={cloudProfile}>
                <SortedGardenerList />
              </CloudProfileContext.Provider>
            </GridContext.Provider>
            <LoadMoreSensor
              checkAndLoadMore={checkAndLoadMore}
              loading_more={loadingMore}
            />
          </div>
          <RegionsLoadingSpinner zonesLeft={zonesLeft} />
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
};

export default GardenerList;
