import { Tab, Grid } from "semantic-ui-react";
import SimpleTable from "../../../components/shared/SimpleTable";
import QuickView from "../../../components/shared/quickview/QuickView";
import { getServerOSIcon, isResizeWaiting, isResizing } from "../utils";
import { getProjectName_from_ProjectList } from "../../../app_shared_functions";
import Resizing from "../bits/Resizing";
import ResizeWaiting from "../bits/ResizeWaiting";
import { useDispatch, useSelector } from "react-redux";
import { toggleSlidingMenu } from "../../../actions/toggleSlidingMenu";
import MonthlyPrice from "../create/price/MonthlyPrice";

function hasFloatingIp(server) {
  return (
    server.addresses &&
    Object.keys(server.addresses).some((key) =>
      server.addresses[key].find(
        (address) => address["OS-EXT-IPS:type"] === "floating",
      ),
    )
  );
}

const Details = ({ server, attachedVolumes, security_groups }) => {
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects.list);

  const isBootingFromVolume = (server) =>
    server?.rootDevice?.type === "volume" &&
    server?.rootDevice?.id !== server?.id;

  const isBootVolume = (server, volume) => volume.id === server?.rootDevice?.id;

  const securitygroups = security_groups.map((security_group, key) => (
    <div key={key}>
      <QuickView>
        <QuickView.Trigger>{security_group.name}</QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`Security Group ID: ${security_group.id}`}</QuickView.Item>
          <QuickView.Modify
            onClick={() =>
              dispatch(
                toggleSlidingMenu("modify", "Security Group", security_group),
              )
            }
          >
            Modify Security Group
          </QuickView.Modify>
          <QuickView.Copy copy={security_group.id}>Copy ID</QuickView.Copy>
          <QuickView.Copy copy={security_group.name}>Copy Name</QuickView.Copy>
          <QuickView.ViewIn
            type="Security Group"
            resource={{
              id: security_group.id,
              project_id: server.project_id,
              region: server.region,
            }}
          />
          <QuickView.Details
            type="Security Group"
            resource={{
              id: security_group.id,
              project_id: server.project_id,
              region: server.region,
            }}
          />
        </QuickView.Content>
      </QuickView>
    </div>
  ));
  const volumes = attachedVolumes
    .sort((volume) => (isBootVolume(server, volume) ? -1 : 1))
    .map((volume, key) => (
      <QuickView key={key}>
        <QuickView.Trigger
          className={
            isBootVolume(server, volume)
              ? "color-disable italic padding-right-half "
              : ""
          }
        >
          {`${volume.name} ${
            isBootVolume(server, volume) ? "{- Boot Volume -} " : " "
          } ${
            !isBootVolume(server, volume) && !volume.name
              ? "{- No Name -} "
              : " "
          } ${volume.size}GB`}
        </QuickView.Trigger>
        <QuickView.Content>
          <QuickView.Item>{`Volume Name: ${
            volume.name || "- No Name -"
          }`}</QuickView.Item>
          <QuickView.Modify
            onClick={() =>
              dispatch(toggleSlidingMenu("modify", "Volume", volume))
            }
          >
            Modify Volume
          </QuickView.Modify>
          <QuickView.Copy copy={volume.id}>Copy ID</QuickView.Copy>
          <QuickView.Copy copy={volume.name}>Copy Name</QuickView.Copy>
          <QuickView.ViewIn type="Volume" resource={volume} />
          <QuickView.Details type="Volume" resource={volume} />
        </QuickView.Content>
      </QuickView>
    ));

  return (
    <Tab.Pane className="">
      <Grid className="columns-3">
        <Grid.Row stackable="true">
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  "ID :",
                  <QuickView>
                    <QuickView.Trigger>{server.name}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Server Name: ${server.name}`}</QuickView.Item>
                      <QuickView.Modify
                        onClick={() =>
                          dispatch(
                            toggleSlidingMenu("modify", "Server", server),
                          )
                        }
                      >
                        Modify Server
                      </QuickView.Modify>
                      <QuickView.Copy copy={server.id}>Copy ID</QuickView.Copy>
                      <QuickView.Copy copy={server.name}>
                        Copy Name
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                ["Flavor :"],
              ]}
            />
            <SimpleTable
              className="striped-table padding-left"
              content={[
                ["ID:", server.flavor.id],
                ["Name:", server.flavor.name],
                [
                  "Size:",
                  server.flavor.vcpus +
                    "vCPUs, " +
                    server.flavor.ramGB +
                    " GB RAM, " +
                    server.flavor.disk +
                    " GB disk",
                ],
              ]}
            />
            <SimpleTable
              className="striped-table reverse-strip-order"
              content={[
                ["Status :", server.status],
                [
                  "Boot Target: ",
                  isBootingFromVolume(server) ? "Volume" : "Ephemeral Disk",
                ],
              ]}
            />
            <SimpleTable
              className="margin-top-half striped-table no-background"
              content={[
                [
                  "Attached volumes: ",
                  attachedVolumes.length > 0 ? "" : "No attached Volumes",
                ],
                ...volumes.map((x) => [x]),
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleTable
              className="striped-table"
              content={[
                [
                  <div className=" flex vcenter justify-between padding-top-00 padding-bottom-00 vcenter">
                    <span>Image OS : </span>
                    <span>{getServerOSIcon(server)}</span>
                  </div>,
                  <QuickView>
                    <QuickView.Trigger>{server.image.name}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Image Name: ${server.image.name}`}</QuickView.Item>
                      <QuickView.Copy copy={server.image.name}>
                        Copy Image Name
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                [
                  "Image id :",
                  <QuickView>
                    <QuickView.Trigger>{server.image.id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Image ID: ${server.image.id}`}</QuickView.Item>
                      <QuickView.Copy copy={server.image.id}>
                        Copy Image ID
                      </QuickView.Copy>
                    </QuickView.Content>
                  </QuickView>,
                ],
                [
                  "Security groups :",
                  <div className="padding-top-00 padding-bottom-00">
                    {securitygroups}
                  </div>,
                ],
                [
                  "Project ID :",
                  <QuickView>
                    <QuickView.Trigger>{server.project_id}</QuickView.Trigger>
                    <QuickView.Content>
                      <QuickView.Item>{`Project ID: ${server.project_id}`}</QuickView.Item>
                      <QuickView.Copy copy={server.project_id}>
                        Copy Project ID
                      </QuickView.Copy>
                      <QuickView.ViewIn
                        type="Project"
                        resource={{
                          id: getProjectName_from_ProjectList(
                            projects,
                            server.project_id,
                          ),
                        }}
                      />
                      <QuickView.Details
                        type="Project"
                        resource={{
                          id: getProjectName_from_ProjectList(
                            projects,
                            server.project_id,
                          ),
                        }}
                      />
                    </QuickView.Content>
                  </QuickView>,
                ],
                ["Availability Zone :", server["OS-EXT-AZ:availability_zone"]],
                ["Created :", server.created],
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            {isResizeWaiting(server) && (
              <div className="bold-section error-section padding-left padding-right padding-top padding-bottom">
                <ResizeWaiting server={server} />
              </div>
            )}

            {isResizing(server) && (
              <p className="padding-top padding-bottom">
                <Resizing server={server} />
              </p>
            )}

            <MonthlyPrice
              flavorId={server.flavor.id}
              regionId={server.region}
              volumeSize={
                server?.rootDevice?.type === "volume"
                  ? server.rootDevice.size
                  : 0
              }
              imageId={server.image.id}
              disasterRecovery={Boolean(server.disasterRecoverService)}
              floatingIp={hasFloatingIp(server)}
              status={server.status}
              rounded
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
};

export default Details;
