import { useMemo } from "react";
import { Icon, Popup, Table } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { getDomainStatusIcon } from "../../../../app_shared_functions";
import { mergeDomainRegionNamesToString } from "../../../../shared-functions/domains";
import { Domain } from "../../../types";
import { State } from "../../../../selectors/state";

const NotProvisionedDomainRow = ({ domain }: { domain: Domain }) => {
  const regions = useMemo(() => {
    return mergeDomainRegionNamesToString(domain);
  }, [domain]);

  const openstackRequests = useSelector(
    (state: State) =>
      state.accountservice?.ACCOUNT_SERVICE_LIST?.openstack?.requests,
  );

  return (
    <Table.Row>
      <Table.Cell title={domain.area.name}>
        <div className={regions ? "" : "italic"}>{domain.area.name}</div>
      </Table.Cell>
      <Table.Cell>
        <div className={regions ? "" : "italic"}>{regions || "Inactive"}</div>
      </Table.Cell>
      <Table.Cell>
        {getDomainStatusIcon(domain, openstackRequests) || (
          <Popup
            trigger={
              <Icon name="exclamation circle" size="large" color="grey" />
            }
          >
            Not provisioned
          </Popup>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default NotProvisionedDomainRow;
